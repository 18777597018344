import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { HomeComponent } from './home/home.component';
import { BasicComponent } from './basic/basic.component';
import { SignComponent } from './sign/sign.component';
import { BalanceComponent } from './balance/balance.component';
import { FinishComponent } from './finish/finish.component';
import { ReactiveFormsModule } from '@angular/forms';
import { WowComponent } from './wow/wow.component';
import { FieldNumberComponent } from './field-number/field-number.component';
import { WaitingComponent } from './waiting/waiting.component';
import { ErrorComponent } from './error/error.component';
import { LocationsComponent } from './locations/locations.component';
import { SoyYoModule } from 'src/app/modules/soy-yo/soy-yo.module';
import { QrComponent } from 'src/app/modules/vinculaciones/qr/qr.component';
import { DesktopMessageComponent } from 'src/app/desktop-message/desktop-message.component';
import { IpErrorComponent } from './ip-error/ip-error.component';


const routes: Routes = [
  {
    path: '',
    // component: MainComponent,
    children: [
      {
        path: 'error',
        component: IpErrorComponent
      },
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'app/basic',
        component: BasicComponent,
      },
      {
        path: 'app/sign',
        component: SignComponent,
      },
      {
        path: 'app/balance',
        component: BalanceComponent,
      },
      {
        path: 'app/finish',
        component: FinishComponent,
      },
      {
        path: 'consultor/:consultor',
        component: HomeComponent,
      },
      {
        path: '**',
        component: HomeComponent,
      },
    ],
  },
];

@NgModule({
  declarations: [
    HomeComponent,
    BasicComponent,
    SignComponent,
    BalanceComponent,
    FinishComponent,
    WowComponent,
    FieldNumberComponent,
    WaitingComponent,
    ErrorComponent,
    LocationsComponent,
    QrComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    SoyYoModule,
  ],
  exports: [
  ],
})
export class VinculacionesModule {}
