import { Component, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SecurityService } from '../services/security.service';
import { LocalstorageService } from '../services/localstorage.service';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  nextRoute = '/app/basic';
  consultor: any;
  showConsultorName;
  validateChange = false;

  constructor(private title: Title,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private localstorageService: LocalstorageService,
              private securityService: SecurityService
    ) {
    this.title.setTitle('Actualización Digital | Inicio');
    const consultor = this.activatedRoute.snapshot.paramMap.get('consultor');

    if(consultor) {
        this.localstorageService.set('consultor', consultor);
        this.securityService.setConsultorUpdatingData(consultor)
          .subscribe({
            next: (response: any) => {
              this.consultor = {
                phone: response.phone,
                name: response.name,
                user: response.user,
                agency: response.agency,
                qrImage: `${environment.serverUrl}/assets/qr/${ response.qrCode }`
              };
              this.showConsultorName = this.consultor != undefined;
            },
            error: (error: any) => {
              console.error('error', error);
            },
          })
        ;
    } else {
      if(!localstorageService.get('consultor')) {
      } else {
        this.securityService.getConsultorUpdatingData()
        .subscribe({
          next: (response: any) => {
            localstorageService.set('qr', `${environment.serverUrl}/assets/qr/${ response.qrCode }`);
            localstorageService.set('phone', response.phone);
            localstorageService.set('consultorName', response.name);
            localstorageService.set('user', response.user);
            localstorageService.set('agency', response.agency);
            this.consultor = {
              phone: response.phone,
              name: response.name,
              user: response.user,
              agency: response.agency,
              qrImage: `${environment.serverUrl}/assets/qr/${ response.qrCode }`
            };
          },
          error: (error: any) => {
            console.error('No hay consultor')
          },
        });  
      }
    }
  }

  async ngOnInit() {
    const route = localStorage.getItem('route');
    const currentUrl = this.router.url;
    if(route && currentUrl !== route) {
      await this.router.navigateByUrl(route);
    }
  }

  async next() {
    const route = this.nextRoute;
    localStorage.setItem('route', route);
    this.router.navigateByUrl(route);
  }

  handleModalEvent(event: boolean){
    this.validateChange = event;
  }
}
