import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { SecurityService } from '../services/security.service';

@Component({
  selector: 'app-sign',
  templateUrl: './sign.component.html',
  styleUrls: ['./sign.component.scss']
})
export class SignComponent implements OnInit, OnDestroy {

  backRoute = '/app/balance';
  nextRoute = '/app/finish';
  wowDisplay = false;
  code = '';
  form: FormGroup;
  timer: any;
  seconds = 60;
  isOtpValidated = false;

  constructor(private router: Router,
              private title: Title,
              private securityService: SecurityService,
    ) {
      this.title.setTitle('Actualización Digital | Firma Vinculación');
      this.form = new FormGroup({
        code: new FormControl('', [Validators.required, Validators.minLength(6)])
      });
    }

  async ngOnInit() {
    const route = localStorage.getItem('route');
    const currentUrl = this.router.url;
    if(route && currentUrl !== route) {
      await this.router.navigateByUrl(route);
    } else {
      this.getOTP();
    }
  }

  getOTP() {
    this.securityService.sendOTP().subscribe({
      next: (response: any) => {
        // console.log(response);
        if(response.isOtpValidated) {
          this.isOtpValidated = true;
        } else {
          this.seconds = 120;
          this.timer = setInterval(() => {
            this.seconds--;
            if(this.seconds === 0) {
              clearInterval(this.timer);
            }
          }, 1000);
          Swal.fire({
            title: 'Código de verificación',
            text: `Se ha enviado el código de verificación a su celular ${response.startPhone}*****${response.lastPhone}`,
            icon: 'info',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#0096d2',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            }
          });
        }
      }  
    });
  }

  ngOnDestroy() {
    clearTimeout(this.timer);
  }

  async next() {
    localStorage.setItem('route', this.nextRoute);
    this.router.navigateByUrl(this.nextRoute);
  }

  async back() {
    localStorage.setItem('route', this.backRoute);
    this.router.navigateByUrl(this.backRoute);
  }

  checkCode() {
    if(this.form.valid || this.isOtpValidated) {
      this.securityService.checkOTP(this.form.value.code, localStorage.getItem('nPhone')).subscribe({
        next: async (response: any) => {
          if(!response.isOtpValidated) {
            await Swal.fire({
              title: 'Código de verificación',
              text: `Se ha confirmado el código de verificación`,
              icon: 'success',
              confirmButtonText: 'Aceptar',
              confirmButtonColor: '#0096d2',
            });
            this.wow();
          } else {
            this.next();
          }
        },
        error: (error: any) => {
          console.error(error);
          Swal.fire({
            title: 'Código de verificación',
            text: `El código de verificación es incorrecto`,
            icon: 'error',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#0096d2',
          })
        },
      });
    }
  }

  wow() {
    this.wowDisplay = true;
  }

  resendCode() {
    this.getOTP();
  }
    

}
