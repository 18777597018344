<div class="px-4 h-[10vh] py-2 flex flex-row justify-between items-center text-white body-blue mb-0">
  <div *ngIf="showBack" (click)="back()">Regresar</div>
  <div [ngClass]="{'justify-end pt-4': showBack, 'justify-start': !showBack}" class="w-full h-full flex items-center">
  </div>
  <div class="h-[10vh] w-full flex flex-row items-center justify-end p-0 float-right">
    <img src="assets/svg/logo-blanco.svg" class="h-[50px] float-right" alt="Logo Fincomercio" />
  </div>
</div>

<div class="flex justify-end w-auto h-6 bg-gray-200 -mt-1">
  <label class="text-white text-[10px] px-2 bg-[#0661ca] rounded-b-2xl" for="">
    <b>Tu asesor:</b> {{ consultor | titlecase }}
  </label>
</div>