<app-header
[showBack] = "false"
[backRoute]="backRoute">

</app-header>
  <div class="h-[90vh] flex flex-col items-center justify-center text-center bg-gray-200">
    <div class="container">
      <div class="row">
        <div class="col d-flex justify-content-center mb-4 text-blue font-extrabold text-[2em]">
            ¡FELICITACIONES!
        </div>
      </div>
      <div class="row">
        <div class="col d-flex justify-content-center text-[1.5rem] font-thin mb-4 text-blue">
            Ya actualizaste tu información
        </div>
      </div>
  
      <div class="row">
        <div class="offset-md-6 col-md-5 text-end p-4">
            <div class="row">
                <button type="button" (click)="downloadPDF()" class="btn btn-primary mt-2 btn-custom">Descargar mi certificado de actualización</button>
                <a type="button" class="btn btn-primary mt-2 btn-custom" (click)="finish()">Finalizar</a>
            </div>
        </div>
      </div>
  
    </div>
  </div>
  