<form [formGroup]="form">
  <div class="mb-3">
      <label for="location" class="form-label text-blue">{{ label }}</label>
      <input type="text" class="form-control" #location id="location" formControlName="location" aria-describedby="locationHelp" 
        placeholder=""
        [ngClass]="{'is-invalid': !isValid && (isTimeout || form.get('location')?.touched)}"
        (blur)="validateBlur()" 
        (keydown)="makeInvalid()"
        (input)="filterLocations($event)"
        (focus)="filterLocations($event)"
        (keydown.escape)="esc()">

        <div class="relative">
          <!-- Loader -->
          <div *ngIf="loading" class="absolute w-full h-full flex items-center justify-center z-10 bg-white opacity-75">
            <app-loader></app-loader>
          </div>
        
          <!-- Lista de ubicaciones -->
          <ul id="list" class="max-h-[300px] relative overflow-y-scroll list-group mt-2" *ngIf="filteredLocations && filteredLocations.length > 0">
            <li class="list-group-item" *ngFor="let location of filteredLocations" (click)="selectLocation(location.id, location.name)">
              {{ location.name }}
            </li>
          </ul>
        </div>
      <div *ngIf="!isValid && (isTimeout || form.get('location')?.touched)" class="text-red-500 text-[0.6em]">Por favor selecciona un elemento de la lista.</div>
  </div>
</form>
