<app-header
[backRoute]="backRoute">
</app-header>
<div class="pt-16 h-[90vh] flex flex-col items-center justify-start text-center bg-gray-200">
  <div class="container">
    <div class="row">
      <div
        *ngIf="!isOtpValidated"
        class="col d-flex justify-content-center mb-4 text-blue font-extrabold text-[2em]"
      >
        Firma tu actualización
      </div>
      <div
        *ngIf="isOtpValidated"
        class="col d-flex justify-content-center mb-4 text-blue font-extrabold text-2xl"
      >
        La actualización ya ha sido firmada
      </div>
    </div>
    <div *ngIf="!isOtpValidated" class="row">
      <div class="col text-center">
        <form [formGroup]="form">
          <div class="mb-3">
            <label for="address" class="form-label text-gray-500"
              >Hemos enviado a tu número celular registrado un código de seis
              (6) digitos, ingresalo aquí para firmar tu actualización:</label
            >
            <input
              formControlName="code"
              type="text"
              class="form-control"
              id="address"
              aria-describedby="addressHelp"
            />
            <!-- <div id="emailHelp" class="form-text">Es la mejor forma de identificarte.</div> -->
          </div>
        </form>
        <div>Podrá reenviar el código en {{ seconds }} segundos...</div>
      </div>
    </div>

    <div>
      <button
        [disabled]="form.invalid && !isOtpValidated"
        type="button"
        class="btn btn-primary btn-custom"
        (click)="checkCode()"
        style="margin-top: 2em"
      >
        Continuar
      </button>
    </div>
    <div *ngIf="seconds == 0" class="row mt-2 p-2 bg-gray-200 rounded-md">
      <div class="h-auto w-full bg-white text-black rounded-md flex items-center justify-center text-xs">
        <p class="m-0 w-full text-center py-3 text-xs">Puedes solicitar nuevamente el código
          <span (click)="resendCode()" style="color: #003085; text-decoration: underline; cursor: pointer;">aquí</span>.
        </p>
      </div>
    </div>
  </div>
</div>
<app-wow *ngIf="wowDisplay" (completed)="next()"></app-wow>
<app-button-help></app-button-help>
