import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class ListService {

  constructor(private httpClient: HttpClient) { }

  getAsotip() {
    return this.httpClient.get(`${ environment.serverUrl }/app/listAsotip/`);
  }

  getCaract() {
    return this.httpClient.get(`${ environment.serverUrl }/app/listCaract`);
  }

  getCodpro() {
    return this.httpClient.get(`${ environment.serverUrl }/app/listCodpro`);
  }

  getIndAct() {
    return this.httpClient.get(`${ environment.serverUrl }/app/listIndAct`);
  }

  getOcupac() {
    return this.httpClient.get(`${ environment.serverUrl }/app/listOcupac`);
  }

  getTipdoc() {
    return this.httpClient.get(`${ environment.serverUrl }/app/listTipdoc`);
  }

  getEmpresas() {
    return this.httpClient.get(`${ environment.serverUrl }/app/listEmpresas`);
  }

  getPensionados() {
    return this.httpClient.get(`${ environment.serverUrl }/app/listPensionados`);
  }

  getUbicacion() {
    return this.httpClient.get(`${ environment.serverUrl }/app/listUbicacion`);
  }

  getUbication(filter: string) {
    return this.httpClient.post(`${ environment.serverUrl }/app/listUbicacion/filterUbication`, {filter});
  }

}
