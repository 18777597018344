<app-header
[backRoute]="backRoute">
</app-header>
<div class="h-[90vh] flex flex-col items-center text-center bg-gray-200 overflow-y-scroll">
  <div class="container">
    <div class="row">
      <div class="pt-4 flex justify-center mb-4 text-blue font-extrabold text-[1.5em]">
        Actualiza tu información
      </div>
    </div>

    <div class="row">
      <div class="col text-start">
        <form [formGroup]="form">
          <app-field-number
            [defaultValue]="form.get('income')!.value"
            [minValidValue]="400000"
            (fieldValue)="setIncome($event)"
            fieldLabel="¿Cuál es tu salario o ingreso principal?"
          ></app-field-number>
          <app-field-number
            [minValidValue]="400000"
            [defaultValue]="form.get('assets')!.value"
            fieldLabel="¿Cuánto suman tus activos (propiedades, vehículos)?"
            (fieldValue)="setAssets($event)"
          ></app-field-number>
          <app-field-number
            [minValidValue]="100000"
            [defaultValue]="form.get('liabilities')!.value"
            fieldLabel="¿Cuánto suman tus deudas?"
            (fieldValue)="setLiabilities($event)"
          ></app-field-number>
          <app-locations
            [label]="'¿Ciudad donde vives?'"
            [timerMs]="timerMs"
            [defaultValue]="form.get('location')!.value"
            (valueState)="setLocation($event)"
          ></app-locations>
        <div class="mb-3">
          <label for="address" class="form-label text-blue"
            >¿Dirección donde resides?</label
          >
          <input
            formControlName="address"
            type="text"
            class="form-control"
            id="address"
            aria-describedby="addressHelp"
            (keyup)="setAddress()"
            [ngClass]="{
              'is-invalid':
                form.get('address')?.invalid &&
                (isTimeout || form.get('address')?.touched)
            }"
          />
          <div
            *ngIf="
              form.get('address')?.invalid &&
              (isTimeout || form.get('address')?.touched)
            "
            class="text-red-500 text-[0.6em]"
          >
            Por favor escriba una dirección válida mayor a 8 carácteres
          </div>
        </div>
        </form>
      </div>
    </div>
    <div>
      <button
        type="button"
        [disabled]="form.invalid"
        class="btn btn-primary btn-custom"
        (click)="saveData()"
        style="margin-top: 2em"
      >
        Continuar
      </button>
    </div>
  </div>
</div>
<app-wow *ngIf="wowDisplay" (completed)="next()"></app-wow>
<app-button-help *ngIf="!wowDisplay"></app-button-help>