export const environmentSDK =  {
  API_KEY: 'PBxc0p3bsb4E2gqSzx29oDwaBgQPFM377ASwIBic',
  CLIENT_ID: '1g1hgvasosc34n5ppvoedjtnj6',
  CLIENT_SECRET: 'v1cpk73cv3ajpni0682cqq1ktmhg8vgbqqhkodpu4e1q8b71eu0',
  PRODUCTION: false,
  ENTITY_ID: '31',
  PROCEDURE_ID: 168,
  APP_IDENTIFIER: 'com.bsccolombia.vinculaciones',
  AUTH_ENDPOINT: 'https://soyyo-snb.auth.us-east-1.amazoncognito.com/oauth2/token',
  USER_ACTIVATION_ENDPOINT: 'https://api.soyyo.mobi/snb-enrollment-process/enrollment-process/v2.0/userActivation/WEB_CLIENT',
  USER_VALIDATION_ENDPOINT: 'https://api.soyyo.mobi/snb-user/user/v2.1/users/validate',
}
