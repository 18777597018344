<div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-md z-50">
    <div class="flex flex-col justify-center items-center w-4/5 h-auto bg-gray-200 rounded-lg shadow-lg p-4">
      <h2 class="text-2xl text-center font-bold mb-4 text-dark-blue"> {{title}} </h2>
      
      <div class="flex justify-center mb-4">
        <img [src]="srcImg" alt="Icono de advertencia" class="w-56 h-56">
      </div>
      
      <p class="text-gray-700 text-center mb-6"> {{text}} </p>
      
      <div class="flex flex-col justify-center w-full">
        <button class="btn-custom" (click)="onButtonClick()">
          {{ buttonText }}
        </button>
        <button *ngIf="showButtonBack" class="button-back mt-2" (click)="onButtonBackClick()">
            {{ buttonBack }}
          </button>
      </div>
    </div>
  </div>