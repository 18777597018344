<ng-container *ngIf="consultor?.user">
  <div class="flex items-center justify-center flex-col h-[40vh] text-center body-blue">
    <img src="assets/svg/logo-blanco.svg" class="w-32 flex" alt="Logo Fincomercio" />
    <div class="text-2xl font-bold">ACTUALIZA TUS DATOS</div>
    <div class="text-[1.3rem] font-thin mb-4 uppercase">
      Cooperativa de ahorro y crédito fincomercio ltda.
    </div>
  </div>

  <div *ngIf="showConsultorName" class="flex justify-end  w-auto h-6 bg-white -mt-1">
    <label class="text-white text-[10px] px-2 bg-[#0661ca] rounded-b-2xl" for="">
      <b>Tu asesor:</b> {{ consultor.name | titlecase }}
    </label>
  </div>
  <div class="h-[60vh] flex flex-col items-center text-center p-2">
    <div class="text-home font-thin mb-2 text-center">
      Mantener tus datos actualizados es un deber como asociado, garantiza el acceso de forma fácil y segura a los beneficios de la Cooperativa y canales transaccionales.

    </div>
    <div class="text-home font-thin mb-2 text-center">

      Autorización tratamiento de datos personales y habeas data autorizo de forma expresa a FINCOMERCIO y dentro de lo estipulado en la Ley 1581 de 2012 y 1266 de 2008, para que reciba, solicite, almacene, consulte, reporte, utilize y entregue la información relacionada con mis datos personales.

    </div>
        <div class="text-home font-thin mb-2 text-center">

      Durante el proceso de actualización validaremos tu identidad con nuestro aliado Redeban-SoyYo. Recibirás mensajes en tu celular, por esto debes tenerlo a mano, al igual que el documento de identificación.

    </div>
        <div class="text-home font-thin mb-2 text-center">

      Si estas listo para iniciar, presiona el botón “Iniciar” a continuación
    </div>
    <div class="flex w-full">
      <button type="button" class="btn btn-primary btn-custom" (click)="next()">
        Iniciar
      </button>
    </div>
   
  </div>
</ng-container>

<ng-container *ngIf="!consultor?.user">
  <div class="flex items-center justify-center flex-col h-[40vh] text-center body-blue">
    <img src="assets/svg/logo-blanco.svg" class="w-32 flex" alt="Logo Fincomercio" />
    <div class="text-2xl font-bold">ACTUALIZA TUS DATOS</div>
    <div class="text-[1.3rem] font-thin mb-4 uppercase">
      Cooperativa de ahorro y crédito fincomercio ltda.
    </div>
  </div>
  <div class="h-[60vh] flex flex-col text-justify overflow-y-scroll p-3">
        <div class="text-base font-thin mb-2 text-center">

      Por favor solicita a tu Asesor Comercial el link o su QR para poder
      continuar con tu proceso de actualización
    </div>
    <div class="text-base font-thin mb-2 text-center">

      <a href="https://www.fincomercio.com" class="text-center">Volver a <span class="font-bold text-blue-800">Fincomercio</span></a>
    </div>
  </div>
</ng-container>  

<app-modal-shared
*ngIf="validateChange"
[title]="'Cambio de Asesor Detectado'"
[srcImg]="'assets/svg/change.svg'"
[text]="'Hemos notado que estás solicitando un cambio de asesor. Esto implicará reiniciar tu proceso desde el principio ¿Estás seguro de que deseas continuar?'"
[buttonText]="'Aceptar'"
(buttonAction)="handleModalEvent($event)"
[showButtonBack] = "true"
[buttonBack] = "'Cancelar'"
(buttonBackAction)="handleModalEvent($event)">

</app-modal-shared>
