import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SecurityService } from '../services/security.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.scss'],
})
export class BalanceComponent implements OnInit {
  backRoute = '/app/basic';
  nextRoute = '/app/sign';
  wowDisplay = false;
  form: FormGroup;

  isTimeout = false;
  timer: any;
  resetTimer = false;
  addressData: any = {};

  allValid = false;
  dataUser: any = {};

  asotips: any[] = [];
  locations: any[] = [];

  timerMs = 20000;

  constructor(
    private router: Router,
    private title: Title,
    private securityService: SecurityService
  ) {
    this.title.setTitle('Actualización Digital | Balance');
    this.form = new FormGroup({
      location: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      income: new FormControl('', [Validators.required]),
      assets: new FormControl('', [Validators.required]),
      liabilities: new FormControl('', [Validators.required]),
    });
    this.timer = setTimeout(() => {
      this.isTimeout = true;
    }, this.timerMs);
  }

  async ngOnInit() {
    const route = localStorage.getItem('route');
    const currentUrl = this.router.url;
    if (route && currentUrl !== route) {
      await this.router.navigateByUrl(route);
    } else {
      this.securityService.getBalance().subscribe({
        next: (response: any) => {
          if (response.income) {
            this.form.get('income')!.setValue(response.income);
          }
          if (response.assets) {
            this.form.get('assets')!.setValue(response.assets);
          }
          if (response.liabilities) {
            this.form.get('liabilities')!.setValue(response.liabilities);
          }
          if (response.location) {
            this.form.get('location')!.setValue(response.location);
          }
          if (response.address) {
            this.form.get('address')!.setValue(response.address);
          }
        },
      });
    }
    setTimeout(() => {
      this.resetTimer = false;
    }, 1);
    this.isTimeout = false;
    this.timer = setTimeout(() => {
      this.isTimeout = true;
    }, 5000);
  }

  async next() {
    localStorage.setItem('route', this.nextRoute);
    this.router.navigateByUrl(this.nextRoute);
  }

  async back() {
    localStorage.setItem('route', this.backRoute);
    this.router.navigateByUrl(this.backRoute);
  }

  getIncome(event) {
    // console.log(event);
  }

  wow() {
    this.wowDisplay = true;
  }

  saveData() {
    if (this.form.valid) {
      this.securityService.setBalance(this.form.value).subscribe({
        next: (response: any) => {
          this.wow();
        },
      });
    }
  }

  setIncomeExtraActivity(event) {
    this.form.get('incomeExtraActivity')!.setValue(event);
  }

  setOutcome(event) {
    this.form.get('outcome')!.setValue(event);
  }

  setAssets(event) {
    this.form.get('assets')!.setValue(event);
  }

  setLiabilities(event) {
    this.form.get('liabilities')!.setValue(event);
  }

  setAddress() {
    this.dataUser!.address = this.form.get('address')!.value;
    this.validateAllValid();
  }

  setLocation(location) {
    // console.log(location);
    this.form.get('location')!.setValue(location);
    this.dataUser!.location = location;
    this.validateAllValid();
  }

  validateAllValid() {
    // console.log(this.dataUser);
    let isAllValid = true;
    for (const key in this.dataUser) {
      if (Object.prototype.hasOwnProperty.call(this.dataUser, key)) {
        const element = this.dataUser[key];
        if (key === 'resolution' || key === 'code') {
          continue;
        }
        if (element === null) {
          //console.log('key', key, this.dataUser[key]);
          isAllValid = false;
        }
      }
    }
    //console.log(isAllValid && this.form.valid, isAllValid, this.form.valid);
    this.allValid = isAllValid && this.form.valid;
  }

  setIncome(income) {
    this.form.get('income')!.setValue(income);
  }

}
