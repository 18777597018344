import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-button-help',
  templateUrl: './button-help.component.html',
  styleUrl: './button-help.component.scss'
})
export class ButtonHelpComponent {

  isModalVisible = false;
  isHelpMessageVisible = true;
  isDragging = false;
  position = { top: window.innerHeight - 90, left: window.innerWidth - 80 }; // Posición inicial del botón
  startDragPosition = { x: 0, y: 0 };

  showHelpMessage() {
    this.isHelpMessageVisible = true;
  }

  hideHelpMessage() {
    this.isHelpMessageVisible = false;
  }

     // Eventos de arrastre con toque
  onTouchStart(event: TouchEvent) {
    this.isDragging = true;
    const touch = event.touches[0];
    this.startDragPosition = { x: touch.clientX - this.position.left, y: touch.clientY - this.position.top };
  }

  onTouchMove(event: TouchEvent) {
    if (this.isDragging) {
      const touch = event.touches[0];
      this.position = {
        top: touch.clientY - this.startDragPosition.y,
        left: touch.clientX - this.startDragPosition.x,
      };
    }
  }

  onTouchEnd(event: TouchEvent) {
    this.isDragging = false;
  }
  openModal() {
    this.isModalVisible = true;
  }

  closeModal() {
    this.isModalVisible = false;
  }

  continueToWhatsApp() {
    this.isModalVisible = false;
    window.open('https://wa.me/3044416214', '_blank');
  }
}